<template>
  <div class="flowpath">
    <span class="guidance-icon"
      ><img src="@/assets/images/guidance-icon.png" alt="" width="126px"
    /></span>
    <span class="line"></span>
    <span class="triangle"></span>
    <el-tooltip placement="top">
      <div slot="content">症状关联疾病<br/>鉴别检验检查推荐</div>
      <button class="appeal">主诉</button>
    </el-tooltip>
    <div class="check">
      <el-tooltip placement="top">
        <div slot="content">检验检查申请审核<br/>禁忌症审核<br/>性别年龄审核<br/>重复审查<br/>注意事项提醒</div>
        <button class="fillet">查体</button>
      </el-tooltip>
      <span class="check-line"></span>
      <!-- <el-tooltip placement="top"> -->
        <!-- <div slot="content"></div> -->
        <button class="fillet">辅助检查</button>
      <!-- </el-tooltip> -->
    </div>
    <div class="diagnosis">
      <el-tooltip placement="top">
        <div slot="content">检验检查推荐<br />用药方案推荐<br />鉴别诊断提示</div>
        <button class="diagnosis-fillet">诊断</button>
      </el-tooltip>
      <!-- <el-tooltip placement="top"> -->
        <!-- <div slot="content"></div> -->
        <button class="diagnosis-fillet">治疗</button>
      <!-- </el-tooltip> -->
      <el-tooltip placement="top">
        <div slot="content">护理评分提醒<br />护理方案建议推荐<br />生命体征危急值提醒</div>
        <button class="diagnosis-fillet">护理</button>
      </el-tooltip>
      <el-tooltip placement="top">
        <div slot="content">出院宣教<br />返院提示</div>
        <button class="diagnosis-fillet">追踪随访</button>
      </el-tooltip>
    </div>
    <div class="horizontal-line"></div>
    <div class="flowpath-right">
      <div class="flowpath-right_top">
        <!-- <el-tooltip placement="top"> -->
          <!-- <div slot="content">多行信息<br />第二行信息</div> -->
          <button class="flowpath-btn">药物治疗</button>
        <!-- </el-tooltip> -->
        <el-tooltip placement="top">
          <div slot="content">相互作用<br />配伍禁忌<br />禁忌症<br />特殊人群用药<br />用法用量审查</div>
          <button class="flowpath-btn">开立遗嘱</button>
        </el-tooltip>
        <el-tooltip placement="top">
          <div slot="content">不良反应监测<br />禁忌症监测<br />TDM监测</div>
          <button class="flowpath-btn">用药监测</button>
        </el-tooltip>
        <el-tooltip placement="top">
          <div slot="content">病情变化监测<br />并发症监测<br />会诊提示<br />并发症治疗方案推荐</div>
          <button class="flowpath-btn" style="margin-left: 27px">
            病情监测
          </button>
        </el-tooltip>
      </div>
      <div class="flowpath-right_bottom">
        <!-- <el-tooltip placement="top"> -->
          <!-- <div slot="content">多行信息<br />第二行信息</div> -->
          <button class="flowpath-btn">手术治疗</button>
        <!-- </el-tooltip> -->
        <el-tooltip placement="top">
          <div slot="content">术前检查规范提醒<br />术前生命体征<br />检验指标异常提醒<br />手术期间用药审核</div>
          <button class="flowpath-btn">预约登记</button>
        </el-tooltip>
        <el-tooltip placement="top">
          <div slot="content">麻醉风险提醒</div>
          <button class="flowpath-btn">麻醉</button>
        </el-tooltip>
        <el-tooltip placement="top">
          <div slot="content">手术风险提醒<br />非计划再手术<br />指征审核</div>
          <button class="flowpath-btn" style="margin-left: 27px">手术</button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.flowpath {
  position: relative;
  .appeal {
    width: 122px;
    height: 40px;
    background: linear-gradient(180deg, #fff3db 0%, #fbb726 100%);
    border-radius: 6px 6px 6px 6px;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    position: absolute;
    top: 45px;
    left: 199px;
    border: 0;
  }
  .line {
    display: inline-block;
    width: 290px;
    border: 1px dashed #fbb726;
  }
  .triangle {
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #fbb726;
    margin-bottom: -7px;
  }
  .fillet {
    display: inline-block;
    width: 108px;
    height: 32px;
    background: #fff6e2;
    border-radius: 20px 20px 20px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #fbb726;
    border: 0;
  }
  .check {
    display: inline-flex;
    flex-flow: column;
    height: 147px;
    justify-content: space-between;
    position: absolute;
    top: -9px;
    left: 317px;
  }
  .check-line {
    display: inline-block;
    width: 0;
    height: 83px;
    border: 1px dashed #fbb726;
    margin-left: 54px;
  }
  .diagnosis {
    width: 108px;
    height: 316px;
    display: inline-flex;
    flex-flow: column;
    justify-content: space-between;
    margin-left: 5px;
    position: absolute;
    top: 49px;
    &::after {
      content: "";
      height: 230px;
      width: 0;
      border: 1px dashed #fbb726;
      position: absolute;
      left: 54px;
      top: 33px;
    }
    &::before {
      content: "";
      width: 0;
      border-top: 12px solid #fbb726;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      bottom: 40px;
      left: 47px;
    }
    .diagnosis-fillet {
      display: inline-block;
      width: 108px;
      height: 32px;
      background: linear-gradient(180deg, #afe5ff 0%, #48c3fe 100%);
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      border: 0;
      position: relative;
      z-index: 9;
    }
  }
  .horizontal-line {
    width: 63px;
    height: 0px;
    border-radius: 0px 0px 0px 0px;
    border: 1px dashed #fbb726;
    display: inline-block;
    position: absolute;
    margin-left: 113px;
    top: 159px;
    &::after {
      content: "";
      width: 0;
      height: 106px;
      border: 1px dashed #fbb726;
      position: absolute;
      margin-left: 65px;
      top: -53px;
    }
  }
  .flowpath-right {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    width: 594px;
    height: 146px;
    position: absolute;
    right: 0;
    top: 90px;
    .flowpath-btn {
      width: 122px;
      height: 40px;
      border: 0;
      border-radius: 6px;
      color: #fff;
      margin-left: 17px;
      position: relative;
      z-index: 9;
    }
    &_top {
      position: relative;
      margin-top: -4px;
      .flowpath-btn {
        background: linear-gradient(180deg, #97b7ff 0%, #375cf0 100%);
      }
      &::before {
        content: "";
        width: 459px;
        height: 0;
        border: 1px dashed #fbb726;
        position: absolute;
        left: -24px;
        top: 19px;
      }
      &::after {
        content: "";
        display: inline-block;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 12px solid #fbb726;
        position: absolute;
        right: 124px;
        top: 12px;
      }
    }
    &_bottom {
      position: relative;
      .flowpath-btn {
        background: linear-gradient(180deg, #a6e34b 0%, #25b602 100%);
      }
      &::before {
        content: "";
        width: 459px;
        height: 0;
        border: 1px dashed #fbb726;
        position: absolute;
        left: -24px;
        top: 19px;
      }
      &::after {
        content: "";
        display: inline-block;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 12px solid #fbb726;
        position: absolute;
        right: 124px;
        top: 12px;
      }
    }
  }
}
</style>